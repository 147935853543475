.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  height: 59px;

  .pagination-actions {
    display: flex;
    align-items: center;

    button {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 14px;
      border: 1px solid #d7d3d0;
      border-radius: 8px;
      color: #57534e;
      font-size: 0.875rem;
      font-weight: 500;
      background: inherit;

      &:hover {
        background-color: #fafaf7;
        color: #2b2c2b;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .pagination-number {
    color: #57534e;
    font-size: 0.875rem;
    font-weight: 500;
  }
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: #262626 !important;
}

.table-nodata {
  text-align: center;
  padding: 90px 10px;
}

.table-wrapper {
  th {
    position: sticky;
    top: 0;
    z-index: 1;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 20px;
    color: #fff !important;
    white-space: nowrap;
    padding: 10px 12px !important;
    background-color: #121212 !important;

    // &:nth-child(1) {
    //   border-radius: 12px 0 0 0;
    // }

    // &:last-child {
    //   border-radius: 0px 12px 0 0;
    // }
  }

  .table > :not(caption) > * > * {
    padding: 13px 0px !important;
    background-color: #000 !important;
    color: #fff !important;
  }

  td {
    font-size: 0.875rem;
    color: #1c1917 !important;
    padding: 12px !important;
  }

  .badge {
    border-radius: 40px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: max-content;
    font-weight: 400;
  }

  .table-responsive {
    max-height: calc(100vh - 12rem) !important;
  }
}

.table-wrapper__border {
  border: 1px solid;
  // border-radius: 12px;
  border-color: #dee2e6 !important;
  overflow: auto;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);

  .top-table-border {
    border-top: 1px solid #dee2e6 !important;
  }
}

.align-table {
  vertical-align: middle;

  &:hover {
    td {
      background-color: #121212;
    }

    .table-checkbox {
      opacity: 1;
    }
  }
}

.table-checkbox {
  &.ant-checkbox-wrapper-checked {
    opacity: 1 !important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #d28b28 !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #d28b28;
    border-color: #d28b28;
    opacity: 1;

    &:hover {
      border-color: #fff;
      background-color: #f9f9f9;
    }
  }

  .ant-checkbox-checked::before {
    opacity: 0.3;
  }

  .ant-checkbox-checked::after {
    border-radius: 4px !important;
  }
}

.ant-avatar {
  height: 24px !important;
  border: unset !important;
  line-height: inherit !important;
  font-variant: inherit !important;
  font-feature-settings: inherit !important;

  display: flex !important;
  align-items: center;
  justify-items: center;
  justify-content: center;
  box-shadow: 0px -0.8px 0.8px 0px #00000033 inset, 0px 0.4px 0.4px 0px #00000014,
    0px 1px 2px 0px #0000001f;

  font-size: 0.75rem !important;
  font-weight: 500 !important;
  width: 24px !important;
  background: #f5f5f4 !important;
  color: rgba(5, 5, 5, 0.5) !important;

  &.plain {
    height: 40px !important;
    width: 40px !important;
    box-shadow: none !important;
    background-color: #f5f5f4 !important;
  }

  &.img-avatar {
    width: 26px;
    background: inherit;
    display: inline-block !important;

    .ant-avatar-string {
      position: static;
    }
  }
}

.ant-popover-inner {
  border: 1px solid #e8e8e7;
  border-radius: 6px !important;
  box-shadow: 0px 12px 16px -4px #1c191714, 0px 4px 6px -2px #1c191708 !important;
}

.ant-popover-arrow {
  bottom: 5px !important;
}

.avatarStyle {
  width: 32px;
  height: 32px;
  background: #bfff0b;
  border-radius: 200px;
  justify-content: center;
  align-items: center;

  display: inline-flex;

  .text {
    text-transform: uppercase;
    width: 24px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-family: Inter;
    font-weight: 500;
    line-height: 18px;
    word-wrap: break-word;
  }

  &:not(:first-child) {
    width: 36px;
    height: 36px;
    margin-left: -8px;
    border: 3px white solid;
  }
}

.fileUpload__table .add-more {
  border: 1px dashed #d9ddd5;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
