@import '../../styles/style-constants.less';

.auth-form-wrapper {
  text-align: center;
  max-width: 600px;
  min-height: min-content;
   padding-bottom: 110px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: @backgroundColor;
  padding-top: 110px;
  .content {
    max-width: 380px;
    width: 100%;
  }
}

.form-header {
  color: @secondaryTextColor !important;
  margin-bottom: 40px;
  h1 {
    color: @secondaryTextColor !important;
    font-size: 30px;
    line-height: 40px;
  }
  h4 {
    color: @secondaryTextColor !important;
    font-size: 15px;
    line-height: 20px;
  }
}

@font-family: Caravel,'Everty Glorial', Manrope, sans-serif;@primary-color: #000;@btn-border-radius-lg: 10px;@input-height-lg: 55px;@btn-height-lg: 60px;