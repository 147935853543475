@import '../../styles/style-constants.less';

.page-wrapper {
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: @primaryColor;

  &.grey {
    background-color: @primaryColor;
    min-height: 100vh;
  }
  .buttonStyle {
    margin: 20px auto;
    width: 100% !important;
    max-width: 380px;

    .customButton, .customButton:focus {
      width: 100% !important;
      width: auto;
    }

  }
  .subHeader {
    font-family: 'Caravel';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: @primaryTextColor;
  }
}

.page-content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.page-content.with-vit {
  width: calc(100% - 380px);
}

.vitrinesb-col {
  // width: 23%;
  width: 380px;
  background-color: @secondaryColor;

  img {
    margin-left: 40px;
    margin-bottom: 20px;
  }
}

.login-vitrine-img {
  width: 340px;
  margin-left: 0px !important;
}
.signup-vitrine-img {
  width: 70%;
}
.logoSpace {
  height: 150px !important;
}
.auth-header {
  width: 100%;
  height: 110px;
  display: flex;

  .logo {
    transform: scale(1.3);
    margin: 70px auto;
  }
}

.ant-form-item-label > label {
  font-size: 15px;
}
.submit-button {
  width: 100%;
  border-radius: 10px;
}

.back-line {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  a {
    color: @primaryTextColor;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}
.secondary-action {
  margin-top: 30px;
  text-align: center;
  font-size: 15px;
  line-height: 20px;
  color: @secondaryTextColor;
  a {
    font-size: 15px;
    line-height: 20px;
    color: @secondaryTextColor;
    font-weight: bold;
  }
}

.verify-logo {
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .vitrinesb-col {
    display: none;
  }
  .page-content.with-vit {
    width: 100%;
  }
}

@font-family: Caravel,'Everty Glorial', Manrope, sans-serif;@primary-color: #000;@btn-border-radius-lg: 10px;@input-height-lg: 55px;@btn-height-lg: 60px;