.onboarding {
  position: relative;
  .onb-header {
    display: flex;
    flex-direction: column;
  }
  .icon-text {
    background: #D28B28;
    height: 50px;
    width: 50px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    text-align: center;
    color: #fff;
    line-height: 1;
    font-weight: 600;
  }

  .tags {
    background: #d9ddd5;
    border-radius: 12px 12px 0 0;
    position: relative;
    padding: 4px 25px;
    width: 100%;
    overflow: hidden;
    span {
      background: #d28b28;
      height: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .welcome-row {
    display: flex;
    align-items: center;
  }
  .status {
    font-size: 13px;
    line-height: 15px;
  }
  .add-btn {
    height: 45px;
    padding: 0 30px;
  }

  .menus {
    margin-top: 50px;
    list-style: none;
    padding: 0;
    border-radius: 6px;
    border-bottom: 1px solid #e6e8e3;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      z-index: 5;
      background: linear-gradient(#ffffff24, #ffffffe0);
      width: 100%;
      height: 75%;
      bottom: 0;
    }
    &.bg-50 {
      &:before{
        height: 50%;
      }
    }
    &.bg-25 {
      &:before{
        height: 25%;
      }
    }
    &.bg-0 {
      &:before{
        height: 0;
      }
    }
  }

  .menu-item {
    display: flex;
    padding: 25px 0px;
    border: 1px solid #e6e8e3;
    border-bottom: 0px;
    color: #586068;

    .icon {
      margin-right: 30px;
      margin-left: 30px;
    }
    .description {
      flex: 1;
      h3 {
        font-size: 22px;
        line-height: 30px;
        margin: 0;
      }
      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
      }
    }

    .action {
      margin-right: 5%;
    }
  }
}

@font-family: Caravel,'Everty Glorial', Manrope, sans-serif;@primary-color: #000;@btn-border-radius-lg: 10px;@input-height-lg: 55px;@btn-height-lg: 60px;