.transaction-wrapper {
  .content {
    height: 95vh;
  }
}
.transaction-icon {
  background: #f2f4f1;
  border-radius: 30px;
  padding: 3px 10px;
  cursor: pointer;
}
.removeIconBg {
  background: transparent;
}

.content {
  height: 95vh;
  position: relative;
}

.edit-category {
  cursor: pointer;
  align-self: flex-start;
  color: #d28b28;
}
.card-modal-footer {
  margin-top: 32px;
}

.item-title {
  margin-bottom: 0px !important;
  color: #BFC6B9;
  font-weight: 400;
  font-size: 14px;
}
.item-value {
  display: flex;
  align-items: center;
  text-align: right;
  word-break: break-all;
  color: #fff;
  svg {
    margin-left: 8px;
  }
}

.item-size {
  font-size: 0.875rem;
}

.item-info {
  a {
    text-decoration: none;
    color: #d28b28;
    text-decoration: underline;
    span {
      color: #d28b28;
    }
  }
}

.transaction-info-box {
  font-size: 12px;
  background-color: #fdead7;
  border-radius: 8px;
  &.box-spacing {
    margin: 24px 32px 0px 32px;
  }
  a {
    text-decoration: underline !important;
    color: #d28b28 !important;
  }
}

.warning-info-box {
  font-size: 12px;
  border: solid 1px #e7e5e4;
  border-radius: 8px;
  &.box-spacing {
    margin: 15px 32px 0px 32px;
  }
  a {
    text-decoration: none !important;
    color: #1c1917 !important;
  }
}

.payment-schedule {
  width: 470px !important ;

  button {
    height: 36px !important;
    font-size: 0.875rem !important;
    padding: 0 16px !important;
    border-radius: 8px !important;
    cursor: pointer;

    &.cancel-button {
      background: inherit !important;
      color: #57534e !important;
      font-weight: 500 !important;
      border: 1px solid #d7d3d0 !important;
      box-shadow: none !important;
    }

    &.confirm-button {
      background: #000000;
      color: white;
      border: none;
    }

    &:hover {
      opacity: 0.75;
    }

    &:active {
      opacity: 0.6;
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}
