.transactions-table {
  width: 100%;

  .ant-table {
    font-size: 14px;
    line-height: 12px;
  }

  .ant-table table {
    border-spacing: 0px 2px;
  }

  .ant-table {
    background: transparent;
  }

  .ant-table-thead {
    th:first-child {
      border-radius: 0 0 0 10px;
      padding-left: 15px;
    }

    th:last-child {
      border-radius: 0 0 10px 0;
      padding-right: 15px;
    }

    & > tr > th {
      background-color: #171721;
      cursor: pointer;
    }
  }

  .ant-table-row {
    height: 50px;
    background-color: transparent;

    td {
      background-color: #171721;
    }

    td.ant-table-cell.ant-table-cell-row-hover {
      background: #292a33 !important;
      border-top: 1px solid #171721;
      border-bottom: 1px solid #171721;

      margin: 2px 0 2px 0;
      cursor: pointer;

      &:first-child {
        border-left: 1px solid #171721;
      }

      &:last-child {
        border-right: 1px solid #171721;
      }
    }

    td:first-child {
      border-radius: 10px 0 0 10px;
      padding-left: 15px;
    }

    td:last-child {
      border-radius: 0 10px 10px 0;
      padding-right: 15px;
    }
  }

  .user-render {
    display: flex;
    align-items: center;
    width: 100%;
    .name {
      margin-left: 5px;
      display: block;
      flex: 1;
    }
  }
}

.company-details-wrapper {
  .back-click {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }

  .headerText {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff !important;

    &.large {
      padding-top: 10px;
      font-size: 35px;
    }
  }

  .subtext {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .details-container {
    .grid {
      display: grid;
    }

    @media (min-width: 768px) {
      .grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr));
      }

      .col-span-8 {
        grid-column: span 8 / span 8;
      }

      .col-span-4 {
        grid-column: span 4 / span 4;
      }
    }

    .details-section {
      background-color: #121212;
      padding: 20px;
    }
  }
}

.grid-cols-2 {
  display: grid !important;
  gap: 1.5rem !important;
  @media (min-width: 1000px) {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}

.grid-cols-4 {
  display: grid !important;
  gap: 1.5rem !important;
  @media (min-width: 1000px) {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 592px !important;
  }
}

@font-family: Caravel,'Everty Glorial', Manrope, sans-serif;@primary-color: #000;@btn-border-radius-lg: 10px;@input-height-lg: 55px;@btn-height-lg: 60px;