@media (min-width: 991.98px) {
  main {
    padding-left: 200px;
  }
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  // padding: 25px 0 0; /* Height of navbar */
  // box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  width: 200px;
  z-index: 600;
  background-color: #121212 !important;
}

@media (max-width: 991.98px) {
  .sidebar {
    width: 100%;
  }
}
.sidebar .active {
  border-radius: 5px;
  // box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  background-color: #121212 !important;
}

.main-content {
  padding: 0px 25px;
}

.nav-link {
  background-color: #121212 !important;
  color: #BFC6B9;
  // padding: 20px 10px;
  margin: 0px 0px 25px 30px;
}

.margin-top-50 {
  margin-top: 50px;
}

//////////

// .fixed {
//   position: fixed !important;
//   top: 0;
//   left: 0;
//   width: 100%;
//   z-index: 99;
// }
// .header-space {
//   height: 72px;
// }
// .user-icon-menu {
//   background: #bfff0b;
//   display: flex;
//   width: 35px;
//   height: 35px;
//   align-items: center;
//   justify-content: center;
//   border-radius: 50%;
//   font-weight: bold;
// }
.top-navbar {
  // background: #f2f4f1;
  padding: 0 !important;
  .logo-link {
    position: relative;
    top: -3.5px;
  }
  .nav-link {
    // padding: 23px 0 !important;
    // margin: 0 20px !important;
    color: #586068 !important;
    border-right: 2px solid transparent;
    border-bottom: none;
  }
  .is-active {
    color: #fff !important;
    font-weight: bold;
    border-color: #fff;
  }
  .moreIcon {
    fill: #586068;
  }
  .line {
    margin: 0 15px;
  }
  .dropdown-toggle::after {
    display: none !important;
  }
  .right-side {
    .search {
    }
    .user {
      .user-icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}
// @media only screen and (max-width: 991px) {
//   .top-navbar {
//     padding: 10px 0 !important;
//     .nav-link {
//       padding: 10px 0 !important;
//     }
//   }
// }


/////////
/// 
/// 
/// 

