/* modal.module.css */

.modal__wrap {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  overflow-x: hidden;
  background-color: rgba(31, 32, 41, 0.75);
  pointer-events: none;
  opacity: 0;
  /* transition: opacity 250ms 700ms ease; */
}

.visible {
  pointer-events: auto;
  opacity: 1;
  /* transition: all 300ms ease-in-out; */
}

.modal {
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  display: block;
  width: 0;
  height: 100%;
  min-height: 100vh;
  padding: 0;
  margin-left: auto;
  background-color: #fff;
  align-self: center;
  box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
  /* transition: 0.5s; */
}

.visible .modal {
  width: 680px;
  padding: 25px 40px;
  /* transition: 0.5s; */
}
