
.img__card-text {
  margin: 0px;
  h5 {
    color: #1c1f2e;
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
  }
  p {
    color: #586068;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin: 0px;
  }
}

.img__card-initials {
  padding: 4px;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  overflow: hidden;
  text-transform: capitalize;
  transition-duration: 300ms;
  border-radius: 100%;
  box-shadow: 0px -0.8px 0.8px 0px #00000033 inset, 0px 0.4px 0.4px 0px #00000014,
    0px 1px 2px 0px #0000001f;

  font-size: 0.75rem;
  font-weight: 500;
  height: 24px;
  width: 24px;
  background: #f5f5f4;
  color: rgba(5, 5, 5, 0.5);

  &.gray {
    background-color: #eaecf5;
  }

  &.yellow {
    background-color: #fefbe8;
  }

  &.blue {
    background-color: #eff8ff;
  }

  &.teal {
    background-color: #f0fdf9;
  }

  &.purple {
    background-color: #f4f3ff;
  }

  &.red {
    background-color: #fef3f2;
  }

  &.medium {
    height: 32px;
    width: 32px;
    font-size: 0.875rem;
    box-shadow: 0px -1.07px 1.07px 0px #00000033 inset, 0px 0.53px 0.53px 0px #00000014,
      0px 1.33px 2.67px 0px #0000001f !important;
  }

  &.normal {
    height: 40px;
    width: 40px;
    font-size: 1.25rem;
    box-shadow: 0px -1.33px 1.33px 0px #00000033 inset, 0px 0.67px 0.67px 0px #00000014,
      0px 1.67px 3.33px 0px #0000001f !important;
  }

  &.large {
    height: 48px;
    width: 48px;
    font-size: 1.125rem;
    box-shadow: 0px -1.6px 1.6px 0px #00000033 inset, 0px 0.8px 0.8px 0px #00000014,
      0px 2px 4px 0px #0000001f !important;
  }

  &.xlarge {
    height: 56px;
    width: 56px;
    font-size: 1.75rem;
    box-shadow: 0px -1.87px 1.87px 0px #00000033 inset, 0px 0.93px 0.93px 0px #00000014,
      0px 2.33px 4.67px 0px #0000001f !important;
  }

  &.x2large {
    height: 64px;
    width: 64px;
    font-size: 1.875rem;
    box-shadow: 0px -2.13px 2.13px 0px #00000033 inset, 0px 1.07px 1.07px 0px #00000014,
      0px 2.67px 5.33px 0px #0000001f !important;
  }
}
