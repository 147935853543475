.fileUpload {
  position: relative;
  .fileUpload-input {
    position: absolute;
    opacity: 0;
    top: 0;
    z-index: -1;
  }
  .loader-line {
    background: #242628;
    // position: absolute;
    bottom: -1px;
    left: 0;
    height: 2px;
    border-radius: 0 0 40px 44px;
  }
  .upload-container {
    text-align: center;
    background: transparent;
    border: 1px dashed #e5e5e5;
    box-sizing: border-box;
    height: 160px;
    border-radius: 12px;
    padding: 12px 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #606060;
    position: relative;
  }
  .arrow {
    position: absolute;
    right: 13px;
    top: 12px;
  }
  .add-more {
    border: 1px dashed #e5e5e5;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.uploaded-file-container {
  border: 1px solid #262626 !important;
}

.uploaded-file-container-name,
.uploaded-file-container-details {
  font-size: 12px;
}

.uploaded-file-container-name {
  font-weight: 500;
  color: #242628;
}

.uploaded-file-container-details {
  color: #6c6c6c !important;
}
