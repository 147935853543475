.transaction-wrapper {
  p {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    color: #BFC6B9 !important;
  }
  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
  }
}
.transaction-icon {
  background: #f2f4f1;
  border-radius: 30px;
  padding: 3px 10px;
  cursor: pointer;
}
.removeIconBg {
  background: transparent;
}
