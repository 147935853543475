.rotate {
  transform: rotate(180deg);
}

.filter-title {
  margin: 0;
  padding: 10px 15px;
  font-size: 15px;
  line-height: 18px;
  color: #000 !important;
  font-weight: bold;
}
.search-wrapper {
  padding: 10px 15px 0;
  .searchIcon {
    color: #586068;
  }
}
.accordion-wrapper {
  padding: 20px 0;
  .accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
    .large-title {
      font-size: 30px;
    }
  }
  .isFilter {
    color: #586068 !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    flex-direction: row-reverse;
    line-height: 15px !important;
    justify-content: flex-end !important;
    svg {
      width: 22px;
      height: 22px;
      position: relative;
      top: 2px;
    }
    rect {
      fill: transparent !important;
    }
  }
}
