.fileUpload {
  position: relative;
  

  .viewFileLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #586068;
  }

  .fileUploadedDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 8px;
    height: 40px;
    background: #121212;
    border: 1px solid #262626;
    border-radius: 5px;
    justify-content: space-between;
    cursor: pointer;
  }
}
