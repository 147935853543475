.customButton {
  max-width: 380px;
  width: 100%;
  height: 54px;
  background: #bfff0b !important;
  box-shadow: 0px 0.4px 0.4px 0px #00000014, 0px 1px 2px 0px #0000001f,
    0px -0.8px 0.8px 0px #00000033 inset !important;
  border-radius: 10px !important;
  color: #000 !important;
  transition: 0.3s;
  font-size: 18px !important;
  line-height: 20px !important;
}

.customButtonWithoutBg,
.customButtonWithoutBg:focus {
  max-width: 380px;
  width: 100%;
  height: inherit;
  border: none !important;
  background: none !important;
  border-radius: 10px !important;
  color: #dc2626 !important;
  font-size: 18px !important;
  line-height: 20px !important;
  outline: none !important;
  box-shadow: none !important;
}

.customConfirmButton,
.customConfirmButton:focus {
  max-width: fit-content;
  width: 100%;
  height: 40px;
  background: #d92d20 !important;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.06) !important;
  border-radius: 10px !important;
  color: #fff !important;
  transition: 0.3s;
  font-size: 16px !important;
  line-height: 20px !important;
  transition: transform 0.3s ease !important;
}

.customConfirmButton:hover {
  background: #cd2115 !important;
  transform: scale(0.98);
}

.button-dimension-fit-content {
  width: fit-content !important;
  min-width: 150px;
  height: 40px !important;
  font-size: 16px !important;
}

.base-button {
  height: 40px !important;
  width: auto !important;
  font-size: 14px;
  min-width: 130px;
  @media not screen {
    max-width: max-content;
  }
}

.base-button:focus {
  font-size: 14px !important;
  line-height: auto !important;
}

.customButton:hover {
  color: #000 !important;
}

button {
  animation-duration: 300ms;

  &:hover {
    opacity: 0.75;
  }

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    opacity: 50;
    cursor: not-allowed;
  }
}

@font-family: Caravel,'Everty Glorial', Manrope, sans-serif;@primary-color: #000;@btn-border-radius-lg: 10px;@input-height-lg: 55px;@btn-height-lg: 60px;