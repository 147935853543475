.search-input-wrapper {
  position: relative;
  display: flex;
  justify-content: end;
  &.show-actions {
    padding: 0;
  }
  input {
    background: #e5e8e3;
    border-radius: 10px;
  }
  .show-input {
    width: 0;
    opacity: 0;
  }

  .show-default-icon {
    width: 35px !important;
    left: 0 !important;
    path {
      stroke: #000 !important;
    }
    right: 0;
  }
  .search-icon {
    position: absolute;
    color: #586068;
    width: 25px;
    top: 3px;
    right: calc(100% - 35px);
    cursor: pointer;
    z-index: 5;
    path {
      stroke: #586068;
    }
  }
}
