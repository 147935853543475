//@font-face {
//  font-family: 'Caravel';
//  src: url('./assets/fonts/Caravel-Regular.otf');
//}
@font-face {
  font-family: 'Caravel';
  src: url('./assets/fonts/Caravel-Regular.otf') format('woff'),
    url('./assets/fonts/Caravel-Regular.otf') format('opentype'),
    url('./assets/fonts/Caravel-Regular.otf') format('truetype');
}

body {
  font-family: 'Caravel !important';
  font-style: normal !important;
  font-weight: 400 !important;
  background-color: #000000 !important;
}
.search-input-wrapper .form-control {
  box-shadow: none !important;
  border-color: transparent !important;
}
.cursor {
  cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-control:focus {
  border-color: #242628 !important;
  box-shadow: 0 0 0 0.25rem #24262821 !important;
}
input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #242628 !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px #24262833 !important;
}

/* width */
::-webkit-scrollbar {
  width: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.user-select-none {
  user-select: none;
}
.rdrDateDisplayWrapper {
  display: none;
}
.rdrDefinedRangesWrapper {
  width: 140px !important;
}
.rdrInputRanges {
  display: none;
}
::placeholder {
  color: #606060 !important;
  opacity: 0.4 !important;
}

:-ms-input-placeholder {
  color: #606060 !important;
}

::-ms-input-placeholder {
  color: #606060 !important;
}
.save-btn {
  background: #d28b28;
  color: #fff;
  padding: 5px 30px;
  border-radius: 30px;
  cursor: pointer;
}
.new-tab {
  font-size: 15px;
  line-height: 20px;
  color: #586068 !important;
  border: 2px solid transparent !important;
  &.active {
    color: #000000 !important;
    font-weight: bold !important;
    border-bottom: 2px solid #000000 !important;
  }
}
.primary {
  .switch-default.ant-switch-checked {
    background: #bfff0b !important;
  }
}
.switch-default.ant-switch-checked {
  background: #000 !important;
}
.form-design {
  .ant-form-item-explain-error {
    color: red;
    font-size: 12px;
  }
  input {
    padding: 12px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    width: 100%;
    &::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: flex-end;
      color: #586068 !important;
      opacity: 0.5;
      flex: none;
      order: 1;
      flex-grow: 1;
      color: #ffffff;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #606060;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #606060;
    }
  }
  label {
    padding-top: 20px;
    font-size: 15px;
    line-height: 20px;
    padding-bottom: 10px;
  }
  .ant-input-affix-wrapper {
    position: relative;
    span.ant-input-suffix {
      position: absolute;
      right: 22px;
      top: -2px;
      z-index: 1;
    }
  }
}
.form-control {
  position: relative;
}
.groupWrapper {
  position: relative;
  color: #ffffff !important;
  .view-password {
    position: absolute;
    top: 51px;
    right: 40px;
    cursor: pointer;
    margin-top: -40px;
    // color: #fff;
  }
  label {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0;
  }
  .form-control,
  .form-select {
    font-weight: 400;
    font-size: 15px;
    background-color: #242628;
    border: 1px solid #586068 !important;
    border-radius: 5px;
    outline: 0;
    box-shadow: none;
    padding: 15px;
    color: #ffffff;

    &:focus {
      background-color: #242628;
      border: 1px solid #586068;
      outline: 0;
      box-shadow: none;
      color: #ffffff;
    }
  }
}

::placeholder {
  color: #eae8e8 !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #eae8e8 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #eae8e8 !important;
}

::placeholder {
  color: #eae8e8 !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #eae8e8 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #eae8e8 !important;
}

.width-full {
  max-width: 100% !important;
}
.form-check-input {
  &:checked {
    background-color: #242628 !important;
    border-color: #242628 !important;
  }
  &:focus {
    border-color: #242628 !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem #2426282e !important;
  }
}

.page-title {
  font-size: 45px;
  line-height: 50px;
  font-weight: bold;
  color: #ffffff;
}
.border-full {
  border: 1px solid #e5e8e3;
}

.borderTop {
  border-top: 1px solid #e5e8e3 !important;
}

.borderRight {
  border-right: 1px solid #e5e8e3 !important;
}

.react-tiny-popover-container {
  z-index: 99999;
}

.innerWrapper {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
}

.borderLeft {
  border-left: 1px solid #e5e8e3 !important;
}

.borderBottom {
  border-bottom: 1px solid #e5e8e3 !important;
}
.customButton,
.customButton:focus {
  max-width: 380px;
  width: 100%;
  background: #242628;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  color: #fff;
  transition: 0.3s;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  border: 0 !important;
}

.customButton:hover,
.customButton:active {
  color: #bfff0b !important;
  background: #242628;
}

@media only screen and (max-width: 600px) {
  .rdrDateRangePickerWrapper {
    flex-direction: column;
  }
}

.form-control {
  border: 1px solid #e5e5e5 !important;
}

.onboardingStepRow {
  justify-content: end;
}

.onboardingStepCol {
  display: flex;
  justify-content: space-evenly;
}

.onboardingStepDiv {
  margin-bottom: 35px;
}

.uploadFileBorder {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  // width: 380px;
  height: 100px;
  background: #f2f4f1;
  border: 2px dashed #e6e8e3;
  border-radius: 4px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  cursor: pointer;
}

.uploadFileTextBorder {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 4px;
  // width: 282px;
  height: 36px;
  opacity: 0.8;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.uploadFileText1 {
  // font-family: 'Caravel ';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  color: #586068;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.uploadFileText2 {
  // font-family: 'Caravel';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #586068;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.uploadedFileText {
  // font-family: 'Caravel';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #586068;
}

.uploadFileIcon {
  text-align: center;
  margin-bottom: 10px;
}

.uploadFileLabel {
  // font-family: 'Caravel';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 10px;
}

.information {
  // font-family: 'Caravel ';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 25px 0px 15px 0px;
}

.owner-information {
  // font-family: 'Caravel ';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: -5px 0px 10px 0px;
}

textarea {
  resize: none !important;
}

.textareaLabel {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  margin: 10px 0;
}

textarea::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  color: #ffffff !important;
  opacity: 0.5;
  flex: none;
  order: 1;
  flex-grow: 1;
}

.margin-top {
  margin-top: 25px !important;
}

.onboardingSavedHeader {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.36px;
  color: #ffffff;
  margin: 20px 0px 10px 0px;
}

.fileUploaded {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 8px;

  // width: 380px;
  height: 40px;

  background: linear-gradient(180deg, #000000 0%, #121212 100%);
  /* gray/light */

  border: 1px solid #e5e5e5;
  border-radius: 5px;
  justify-content: space-between;
  margin-top: 10px;
}

.datepicker-form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  outline: none;
  // border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
  // border: 1px solid #e5e5e5 !important;
}

.datepicker-form-control:focus {
  border-color: none !important;
  border: none !important;
  outline: none;
  box-shadow: none !important;
}

.datepicker-div {
  display: flex;
  color: #fff !important;
  // flex-direction: row;
  align-items: center;
  padding: 8px 14px 8px 0px !important;
  gap: 8px;
  width: 100% !important;
  background: #242628;
  border: 1px solid #606060;
  height: 54px !important;
  border-radius: 4px !important;
  flex: none;
  order: 0;
  align-self: stretch;
  margin: 0px;
  // justify-content: space-between !important;

  .datepicker-form-control {
    background: #242628 !important;
    color: #fff !important;
  }
}

.left-clock {
  padding: 3.5px 0px 3.5px 14px !important;
}

.datepicker-div:focus {
  border-color: #242628 !important;
  box-shadow: 0 0 0 0.25rem #24262821 !important;
}

.datepicker-div:hover {
  border-color: #242628 !important;
  box-shadow: 0 0 0 0.25rem #24262821 !important;
}

.datepicker-div:active {
  border-color: #242628 !important;
  box-shadow: 0 0 0 0.25rem #24262821 !important;
}

.css-1s2u09g-control {
  height: 50px !important;
  border: 1px solid #121212;
  border-radius: 4px;
  outline: none !important;
}

.css-1s2u09g-control:focus {
  border-color: #121212 !important;
  box-shadow: 0 0 0 0.25rem #24262821 !important;
  outline: none !important;
}

.creatable-select {
  // border-color: #242628 !important;
  // box-shadow: 0 0 0 0.25rem #24262821 !important;
  outline: none !important;
}

.creatable-select:focus {
  border-color: #242628 !important;
  box-shadow: 0 0 0 0.25rem #24262821 !important;
  outline: none !important;
}

.css-b62m3t-container {
  // border-color: #242628 !important;
  // box-shadow: 0 0 0 0.25rem #24262821 !important;
  outline: none !important;
}

.css-b62m3t-container:focus {
  border-color: #242628 !important;
  box-shadow: 0 0 0 0.25rem #24262821 !important;
  outline: none !important;
}

.css-1s2u09g-control {
  // border-color: #242628 !important;
  // box-shadow: 0 0 0 0.25rem #24262821 !important;
  outline: none !important;
}

.css-1s2u09g-control:focus {
  border-color: #242628 !important;
  box-shadow: 0 0 0 0.25rem #24262821 !important;
  outline: none !important;
}

.card-body {
  padding: 1rem 0.5rem !important;
}

.modal-sub-header {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #586068;
  margin-bottom: 25px;
}

.viewReimbursementTile {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
}

.viewReimbursementValue {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.viewReimbursementStatusDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.delete-sub-header {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #586068;
}

.delete-btn {
  justify-content: center !important;
  align-items: center !important;
  padding: 12px 24px !important;
  gap: 8px !important;
  // width: 380px !important;
  height: 44px !important;
  background: #dc2626 !important;
  border-radius: 8px !important;
}

.cancel-delete {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #242628;
  cursor: pointer;
}

// ////////

h2 {
  color: #ffffff !important;
}

h3 {
  color: #ffffff !important;
}

p {
  color: #ffffff !important;
}

.secondary-color {
  color: #bfff0b;
}

.text-color {
  color: #ffffff !important;
}

.statistics-title-div {
  color: #ffffff;
  margin-top: 15px;
}

.overview-statistics {
  box-shadow: inset 1px 0px 0px #262626;
  background: #121212;
  border-radius: 3px;
  padding: 25px;
}

.overview-statistics-ellipse {
  margin-right: 15px;
}

.statistics-title {
  color: #ffffff;
  margin-right: 7px;
}

.statistic-number {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  color: #bfc6b9;
}

.statistic-number-completed {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
}

.ps-1 {
  font-size: 12px;
  // margin-bottom: 7px;
}

.chartActon {
  // font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #bfc6b9;
}

.modal_modal__q21-u {
  background: #000000 !important;
  color: #fff;
}

.chartWrapper {
  box-shadow: inset 1px 0px 0px #262626 !important;
  background: #121212 !important;
  border-radius: 3px !important;
  padding: 25px !important;
}

.recent-transactions-wrapper {
  box-shadow: inset 1px 0px 0px #262626 !important;
  background: #000 !important;
  border-radius: 3px !important;
  padding: 0px 25px !important;
  margin-top: 30px;
  color: #fff !important;
}

.titleClass {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #bfc6b9 !important;
}

.valueClass {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
}

.edit {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  margin-bottom: 25px;
  cursor: pointer;
  color: #bfc6b9;
}

.css-1of3s7i-control {
  background-color: #242628 !important;
}

.css-26l3qy-menu {
  background-color: #242628 !important;
}

.css-4ljt47-MenuList {
  background-color: #242628 !important;
}

.css-1n7v3ny-option {
  background-color: #242628 !important;
}

.css-1ki5tpo-control {
  background: #121212 !important;
  border: 1px solid #262626 !important;
  border-radius: 5px !important;
}

.form-group {
  .form-label {
    font-size: 0.875rem;
    color: #fff !important;
    font-weight: 500;
    cursor: pointer;
    margin: unset !important;
    margin-bottom: 0.5rem !important;

    &.custom {
      margin-bottom: 4px !important;
    }
  }

  input {
    height: 40px !important;
    font-size: 0.875rem !important;
    box-shadow: 0px 1px 2px 0px #1c19170d !important;
    border-radius: 8px;

    &:disabled {
      background: #f5f5f4;
      color: #d7d3d0;
      box-shadow: none;
    }
  }

  textarea {
    box-shadow: 0px 1px 2px 0px #1c19170d;
    border-radius: 8px;
    color: #1c1917;
    font-size: 0.875rem;
    font-weight: 400 !important;
    line-height: 28px;

    &::placeholder {
      font-size: 0.875rem;
    }

    &:disabled {
      background: #ffffff;
    }
  }

  &.custom {
    input {
      height: unset !important;
    }
  }

  .dropdown-select__control {
    border-radius: 8px !important;
    min-height: unset !important;
    min-height: 40px;
    padding: 1px 3px !important;
    cursor: pointer;

    &.hasError {
      border-color: #fda29b !important;
      box-shadow: 0px 0px 0px 4px #fee4e2 !important;
    }

    .dropdown-select__input {
      min-width: max-content !important;
      font-size: 0.875rem !important;
      box-shadow: none !important;
      border-radius: 0px !important;
    }

    &.dropdown-select__control--is-focused {
      box-shadow: 0 0 0 0.25rem rgba(36, 38, 40, 0.1294117647) !important;
    }

    .dropdown-select__indicator {
      transition: transform 0.3s ease-in-out;
    }

    &.dropdown-select__control--menu-is-open .dropdown-select__indicator {
      transform: rotateX(180deg);
    }

    .dropdown-select__single-value {
      color: #1c1917 !important;
      font-weight: 400;
      font-size: 0.875rem;

      &.is-placeholder {
        color: #a9a29d !important;
      }
    }

    .dropdown-select__placeholder {
      color: #a9a29d;
      font-size: 0.875rem;
    }
  }
}

.date-select {
  height: 40px;
  box-shadow: 0px 1px 2px 0px #1c19170d;
  border-radius: 8px;

  &.disabled {
    box-shadow: none;
  }

  input {
    color: #1c1917;
    height: auto;
    font-size: 0.875rem;
    box-shadow: none !important;
    border-radius: 0px !important;
  }
}

.search-input {
  width: 16rem;
  @media screen and (max-width: 480px) {
    width: 12rem !important;
  }

  .input {
    height: 36px;

    border-radius: 8px !important;
    padding: 0 12px !important;
    border: 1px solid #e7e5e4 !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);

    &:hover {
      border-color: #e7e5e4 !important;
    }

    .ant-input {
      padding-left: 6px !important;
      background-color: transparent !important;
      color: #fff !important;
      &::placeholder {
        color: #fff !important;
        font-weight: 500;
        background-color: transparent !important;
      }
    }
  }
}

#invoice-schedule > .popover-body > .actions-dialog {
  right: -10px;
  position: relative;
  min-width: 240px;
}

.actions-dialog {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 11111;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 5px 0;
  margin-top: 10px;
  border: 1px solid gainsboro;
  max-height: 420px;
  overflow-y: scroll;
  min-width: 240px;

  &.isInput {
    margin-top: 7px;
    margin-left: -17px;
  }

  .actionLink {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px !important;
    font-size: 14px;
    font-weight: 500;

    &.gap-8 {
      gap: 8px !important;
    }

    &:hover {
      background: whitesmoke;
    }

    span {
      padding: 0 10px 0 5px;
    }
  }

  .svg-danger {
    svg {
      margin-right: 8px;
    }

    svg path {
      stroke: red;
    }
  }
}

.link-icon {
  display: flex;
  align-items: center;
  margin-left: 10px;

  .not-active {
    opacity: 0.3;
    cursor: not-allowed;
    pointer-events: none;
  }

  .icon-btn {
    font-size: 15px;
    background: #d9ddd5;
    margin: 4px;
    padding: 7px;
    border-radius: 50%;
    cursor: pointer;
  }
}

.btn {
  &.green {
    border-radius: 8px !important;
    border: 1px solid #bfff0b;
    background: #bfff0b !important;
    display: flex;
    min-width: fit-content !important;
    justify-content: center;
    align-items: center;
    color: #000 !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  &.danger {
    border-radius: 8px !important;
    border: 1px solid #d92d20;
    background: #d92d20 !important;
    display: flex;
    min-width: fit-content !important;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  &.border {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-radius: 8px;
    border: 1px solid #262626;
    background: #121212;
    display: flex;
    min-width: fit-content !important;
    justify-content: center;
    align-items: center;
  }

  &.w-full {
    width: 100%;
  }

  &.w-fit {
    width: fit-content;
  }

  &:hover.black {
    background: #292524 !important;
  }

  &:hover.border {
    background: transparent;
    color: #fff;
  }

  &.sm {
    font-size: 14px;
    padding: 10px 16px;
  }

  &.xs {
    font-size: 14px;
    padding: 6px 16px;
  }

  &.xxs {
    font-size: 14px;
    padding: 6px 8px;
  }
}
