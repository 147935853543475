.toast-wrap {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  gap: 6px;
  height: 16px !important;
  border-radius: 200px !important;
}

.icon-wrap {
  flex-basis: 8.333333%;
  line-height: 0px;
  margin: 0;
  padding: 0;
  .success-icon {
    font-size: 20px;
    color: #bfff0b;
    line-height: 0px;
    margin: 0;
    padding: 0;
  }
  .other-icons {
    font-size: 20px;
    color: #fff;
    line-height: 0px;
    margin: 0;
    padding: 0;
  }
}
.text-wrap {
  flex-basis: 83.333333%;
  line-height: 0px;
  margin: 0;
  padding: 0;
  .text {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 300;
    color: #fff;

    &.success {
      color: #1c1917;
      background: transparent !important;
    }

    &.warning {
      color: #fff;
      background: transparent !important;
    }

    &.error {
      color: #fff;
      background: transparent !important;
    }
  }
}

.success__toast_custom {
  width: 100% !important;
  min-height: 10% !important;
  max-height: 100% !important;
  background: #fff !important;
  border: 1px solid #e7e5e4 !important;
  border-radius: 8px !important;
}

.warning__toast_custom {
  width: 100% !important;
  min-height: 10% !important;
  max-height: 100% !important;
  background: #f79009 !important;
}

.error__toast_custom {
  width: 100% !important;
  min-height: 10% !important;
  max-height: 100% !important;
  background: #d92d20 !important;
  border-radius: 8px !important;
}
