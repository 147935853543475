 @import "../../../styles/style-constants.less";

.vitrinesb-wrapper{
  width: 100%;
  background-color:@backgroundColor;
  min-height: 100vh;
  font-family: Caravel ;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .app-logo{
    margin-top: 35px;
    margin-left: 40px;
  }

  .message{
    margin-top: 60px;
    max-width: 300px;
    margin-left: 40px;
  }

  .link{
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    text-decoration:underline;
    margin-left: 40px;
    display: block;
    width: 100%;
    margin-top: 45px;
    color: #000000;
  }

}


@font-family: Caravel,'Everty Glorial', Manrope, sans-serif;@primary-color: #000;@btn-border-radius-lg: 10px;@input-height-lg: 55px;@btn-height-lg: 60px;