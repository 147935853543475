.transaction-wrapper {
  p {
    font-size: 15px;
    line-height: 20px;
    color: #586068;
    margin: 0;
    font-weight: 400;
  }
  h3 {
    font-size: 18px;
    line-height: 25px;
    margin: 0;
  }
}
.transaction-icon {
  background: #f2f4f1;
  border-radius: 30px;
  padding: 3px 10px;
  cursor: pointer;
}
.removeIconBg {
  background: transparent;
}
