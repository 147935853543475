.badge-wrapper {
  border-radius: 40px;
  overflow: hidden;
  display: inline-flex;
  line-height: 0;
  white-space: nowrap;
  min-width: 50px;

  .badges-inherit {
    width: 100%;
    background: inherit !important;
    font-size: 0.75rem;
    white-space: nowrap;
    font-weight: 500 !important;
  }
}

.dotted-badge {
  border-radius: 40px;
  overflow: hidden;
  border-style: dotted;
  border-color: #e1e2e4;
  white-space: nowrap;
  padding: 0 8px;
}

.table-card {
  img {
    width: 40px !important;
  }
}

.fw-light-bold {
  font-weight: 500;
}

.fw-200 {
  font-weight: 300;
}

.silver-color {
  color: #586068;
}

.mr-8 {
  margin-right: 18px;
}

.with-substring {
  line-height: 16px;
  margin-bottom: 8px;
  padding-top: 4px;
  font-size: 0.75rem;
  font-weight: normal !important;
  white-space: nowrap;
  color: #79716b;
}

@media (max-width: 1024px) {
  .with-substring {
    font-size: 10px;
  }

  .badge-wrapper {
    font-size: 13px;

    .badges-inherit {
      font-size: 13px;
    }
  }

  .dotted-badge {
    font-size: 13px;
  }

  .table-font-size {
    font-size: 13px;
  }

  .string-type-font {
    font-size: 13px;
  }

  .actionLink {
    font-size: 13px !important;
  }
}

.table-font-size {
  white-space: nowrap;
}

.string-type-font {
  white-space: nowrap;
}

.line-height-0 {
  line-height: 0;
}

.actionLink {
  white-space: nowrap;
}

.table-lines {
  background: #e7e5e4;
  height: 20px;
  position: relative;
  overflow: hidden;
  border-radius: 5px !important;

  div {
    position: absolute;
    height: 20px;
    top: 0;
    left: 0;
  }
}
