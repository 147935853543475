.card-title {
  font-size: 30px;
  line-height: 40px;
}
.user-view-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  border: 1px solid #e5e5e5;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 10px;
  .userIcon {
    background: #bfff0b;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 16px;
    margin-right: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .useRole {
    background: #ecf3f9;
    border-radius: 35px;
    padding: 3px 15px 2px;
  }
}
.balance {
  .subHeaderText {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #586068;
  }
  .cardWrapper {
    margin-top: 50px;
    .active-card-bg {
      box-shadow: 0 3px 3px #e7e7e7;
      background: #e6e8e34f;
    }
    .balance-card-wrapper {
      border: 1px solid #e5e5e5;
      border-radius: 0.25rem;
      margin-bottom: 20px;
      padding: 20px 15px;
      align-items: center;
      display: flex;
      cursor: pointer;

      .balance-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
      }
      .balance-tag {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #ffffff;
        background: #d28b28;
        border-radius: 50px;
        padding: 3px 8px;
        margin-left: 12px;
      }
      .dark-bg {
        background: #586068 !important;
      }
      .balance-sub-title {
        color: #586068;
        font-size: 15px;
      }
    }
  }
}
.InfoBox-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6e8e3;
  padding: 20px 5px;
  &:last-child {
    border-bottom: 0;
    margin-bottom: 20px;
  }
  .information-title-wrapper {
    .information-title {
      font-size: 15px;
      line-height: 20px;
      color: #586068;
      margin: 0;
    }
    .information-value {
      font-size: 18px;
      line-height: 25px;
      font-weight: bold;
      margin: 0;
    }
  }
  .copy-box {
    background: #f2f4f1;
    border-radius: 30px;
    padding: 5px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 13px;
    svg {
      margin-left: 10px;
      margin-top: 3px;
    }
  }
}
