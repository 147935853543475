.fixed {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}
.header-space {
  height: 72px;
}
.user-icon-menu {
  background: #bfff0b;
  display: flex;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
}
.top-navbar {
  // background: #f2f4f1;
  padding: 0 !important;
  .logo-link {
    position: relative;
    top: -3.5px;
  }
  .nav-link {
    // padding: 23px 0 !important;
    // margin: 0 20px !important;
    color: #586068 !important;
    border-bottom: 2px solid transparent;
  }
  .is-active {
    color: #000 !important;
    font-weight: bold;
    border-color: red;
  }
  .moreIcon {
    fill: #586068;
  }
  .line {
    margin: 0 15px;
  }
  .dropdown-toggle::after {
    display: none !important;
  }
  .right-side {
    .search {
    }
    .user {
      .user-icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .top-navbar {
    padding: 10px 0 !important;
    .nav-link {
      padding: 10px 0 !important;
    }
  }
}
