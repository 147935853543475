.no-data {
  padding: 0 !important;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-self: center;
  width: 380px !important;
  .textHeader {
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
    text-align: center;
    margin: 5px 0;
  }
  .subHeaderText {
    font-size: 15px;
    line-height: 20px;
    color: #586068;
  }
}
