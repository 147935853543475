.edit-button,
.edit-button:focus {
  width: 81px;
  height: 29px;

  background: #171721;
  border: 1px solid #535860;
  box-sizing: border-box;
  border-radius: 4px;

  font-size: 12px;
  line-height: 13px;
  margin-bottom: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #535860;
}

.edit-button:hover,
.edit-button:active {
  background: linear-gradient(135deg, #e97655 2.88%, #743ac3 100%) !important;
  border: none !important;
  color: #ffffff !important;
}

@font-family: Caravel,'Everty Glorial', Manrope, sans-serif;@primary-color: #000;@btn-border-radius-lg: 10px;@input-height-lg: 55px;@btn-height-lg: 60px;