.businessType {
    .savingText {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
        flex: none;
        order: 0;
        flex-grow: 0;
    }
}
